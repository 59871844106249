import { message, Popconfirm } from "antd";
// import ComonButton from "./ButtonCustom";
import * as URL from "./../../config/config";
import * as API from "../../Services/API";

const BtnCellRenderer = (props: any) => {
  // const [showDelete, setShowDelete] = useState(false);

  async function handleSubmit() {
    console.log("props=======>", props);
    let res = await API.HandleMethodDelete(
      `${URL.USERTOKEN}/${props?.data?._id}`
    );
    console.log("res=========>", res);
    if (res && res.result && res.result.deletedCount === 1) {
      message.success("Delete successful");
      props?.api?.refreshServerSideStore({ purge: true });
    } else {
      message.error("Delete failed");
    }
    // setShowDetail(true);
  }

  return (
    <>
      {/* <ComonButton
        type={"LINK"}
        onClick={handleSubmit}
        text={"Delete"}
        loading={false}
      ></ComonButton> */}
      <Popconfirm
        title="Are you sure？"
        okText="Yes"
        onConfirm={handleSubmit}
        cancelText="No"
      >
        <a href="/#">Delete</a>
      </Popconfirm>
    </>
  );
};

export default BtnCellRenderer;

/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from "react";
import * as API from "../../Services/API";
import "./ServerSideTable.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
// import BtnCellRenderer1 from "./Buttons/BtnCellRenderer1";
import { useHistory } from "react-router";
import { TRANSLATIONS } from "./translation";
import { columnTypes } from "./../../utilities/Aggrid/agGrid";
import BtnCellRenderer from "../Buttons/BtnCellRenderer";

const ServerSideTable = ({
  ud,
  setUd,
  columnDefs,
  LINK,
  type,
  setDetailData,
  setDetail,
  setVisible,
  setCheckService,
  setType,
  approvalType,
  isHorScroll,
  queryTab,
}: any) => {
  const history = useHistory();
  const pageSize = 1000;
  const [gridApi, setGridApi] = useState<any>();
  const [loading, setLoading] = useState(false);

  const gridOptions = {};

  useEffect(() => {
    if (ud) {
      gridApi?.refreshServerSideStore({ purge: true });
      setUd(false);
    }
  });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    // params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource(ServerSideDatasource(params.columnApi));
    // params.api.forEachNode((node: any) => (node.rowIndex === 1 ? node.setSelected(true) : node.setSelected(false)));
  };

  const getCurrentRender = () => {
    return BtnCellRenderer;
  };

  const ServerSideDatasource = (gridColAPI: any) => {
    return {
      async getRows(params: any) {
        try {
          const { sortModel, filterModel } = params.request;
          console.log({ sortModel, filterModel, params });
          const offset = params.request.endRow - pageSize;
          let queryString = `?limit=${pageSize}&offset=${offset}`;

          if (queryTab) {
            queryString += `&${queryTab.field}[${queryTab.type}]=${queryTab.value}`;
          }

          if (approvalType) {
            queryString += approvalType;
          }
          if (sortModel.length > 0) {
            queryString += `&${sortModel[0].colId}[sort]=${sortModel[0].sort}`;
          } else {
            queryString += `&createdAt[sort]=desc`;
          }
          if (Object.keys(filterModel).length > 0) {
            for (const key in filterModel) {
              if (filterModel[key].dateFrom) {
                queryString += `&${key}[${filterModel[key].type}]=${new Date(
                  filterModel[key].dateFrom
                ).getTime()}`;
              } else if (filterModel[key].filterType === "set") {
                if (filterModel[key].values.length === 0) {
                  queryString += `&${key}[in]=undefined`;
                } else {
                  queryString += `&${key}[in]=${filterModel[key].values}`;
                }
              } else {
                queryString += `&${key}[${filterModel[key].type}]=${filterModel[key].filter}`;
              }
            }
          }

          // console.log('LINK======>', `${LINK}${queryString}`);

          if (setLoading) setLoading(true);
          let res: any = await API.HandleMethodGet(`${LINK}${queryString}`);
          // let res: any = await API.HandleMethodGet(`${LINK}`);
          console.log("responsed Data=================>", res.us);

          const total = res?.pagination?.totalRows;
          if (setLoading) setLoading(false);
          // if (res.data.length > 0) {
          params.successCallback(res.us, total || res.us.length);
          // } else {
          // params.successCallback([{ STT: 'No result found' }], 1);
          // }

          console.log("loading", loading)

          if (gridColAPI) {
            if (isHorScroll) gridColAPI.autoSizeAllColumns();
          }
        } catch (error) {
          // params.successCallback('STT', 0);
          console.log("error========>", error);
          params.successCallback([{ STT: "No result found" }], 1);
          // type === 'admin' ? params.successCallback(fakeData.data, 2) : params.successCallback('', 0);

          if (gridColAPI) {
            if (isHorScroll) gridColAPI.autoSizeAllColumns();
          }
          console.log(error);
        }
      },
    };
  };

  const onSelectionChanged = async () => {
    // var selectedRows = gridApi.getSelectedRows();
    // console.log('selectedRow======>', selectedRows);
  };

  const onCellClicked = async (event: any) => {
    // if (type !== 'admin') return;
    var selectedRows = gridApi.getSelectedRows();
    if (type === "investor" && event.colDef.field === "investorName") {
      history.push(`/investorManagement/${selectedRows[0].investorCode}`);
    }
    if (type === "admin") {
      setDetailData(selectedRows[0]);
      setCheckService(selectedRows[0].functions);
    }
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        marginLeft: 10,
        marginTop: 0,
        width: "calc(100% - 20px)",
        height: "calc(100% - 10px)",
      }}
    >
      <AgGridReact
        localeText={TRANSLATIONS["vi-VI"]}
        serverSideStoreType={"partial"}
        animateRows={true}
        pagination={true}
        rowModelType={"serverSide"}
        paginationPageSize={pageSize}
        cacheBlockSize={pageSize}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        columnTypes={columnTypes()}
        rowSelection={"single"}
        domLayout={"autoHeight"}
        onSelectionChanged={onSelectionChanged}
        onCellClicked={onCellClicked}
        frameworkComponents={{
          BtnCellRenderer: getCurrentRender(),
        }}
        // getContextMenuItems={getContextMenuItems}
        headerHeight={35}
        rowHeight={30}
        gridOptions={gridOptions}
        suppressAutoSize={true}
        rowClassRules={{
          "ag-red": function (params) {
            // console.log('params======>', params);
            var rowIndex = params.rowIndex;
            return rowIndex % 2 !== 0;
          },
        }}
        defaultColDef={{
          flex: 1,
          headerClass: "header-background",
          cellClass: "cellClass",
          // minWidth: 200,
          resizable: true,
          sortable: true,
          filter: true,
          // editable: true,
          menuTabs: ["filterMenuTab"],
        }}
      />
    </div>
  );
};

export default ServerSideTable;

import { Button } from "antd";
import { useState } from "react";
import "./ButtonCustom.css";

const ButtonCustom = ({ onClick, text, loading, type }: any) => {
  const [dis, setDis] = useState(false);
  // var timeOut: any;

  const handleClick = () => {
    if (!dis) {
      onClick();
      setDis(true);
      setTimeout(() => {
        setDis(false);
      }, 900);
    }
  };

  if (type === "add") {
    return (
      <Button
        className="comon-button"
        loading={loading}
        type="primary"
        onClick={handleClick}
        style={{ backgroundColor: "#166872", marginRight: 0 }}
      >
        <div style={{ color: "white" }}>{text}</div>
      </Button>
    );
  }

  if (type === "LINK") {
    return (
      <Button
        type="link"
        onClick={handleClick}
      >
        {text}
      </Button>
    );
  }

  return (
    // <Button
    //   className="comon-button"
    //   loading={loading}
    //   type="primary"
    //   onClick={handleClick}
    //   style={{ backgroundColor: "#f8a83c" }}
    // >
    //   <div style={{ color: "black", marginTop: 0 }}>{text}</div>
    // </Button>
    <Button
      style={{ backgroundColor: "#f8a83c" }}
      onClick={handleClick}
      type="primary"
      loading={loading}
    >
      {text}
    </Button>
  );
};

export default ButtonCustom;

import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authenticate from "./components/Auth/Authentication";
import Admin from "./components/Admin/Admin";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={Authenticate} />
        <Route path="/" component={Admin} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

// import { Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Redirect } from "react-router";
import ButtonCustom from "../Buttons/ButtonCustom";
// import InputField from "../InputFormCustom/InputField";
import ServerSideTable from "../ServerSide/ServerSideTable";
import * as URL from "./../../config/config";
import "./styles.css";
import CreatePop from "./CreatePop";

var token = localStorage.getItem("token");
const columnDefs = [
  {
    headerName: "STT",
    suppressMenu: true,
    suppressSorting: true,
    resizable: true,
    sortable: false,
    valueGetter: "node.rowIndex+1",
    maxWidth: 80,
  },
  {
    field: "usertoken",
    headerName: "User token",
    // valueGetter: getEcxactDate,
    // cellRenderer: (e: any) => {
    //   return e.value.fullName;
    // },
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    minWidth: 200,
    type: "text",
  },
  {
    field: "userName",
    headerName: "User name",
    // valueGetter: getEcxactDate,
    // cellRenderer: (e: any) => {
    //   if (e && e.value && e.value.fullName) return e.value.fullName;
    // },
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    minWidth: 200,
    type: "text",
  },
  {
    field: "createdAt",
    headerName: "Created time",
    filter: "agDateColumnFilter",
    cellRenderer: (e: any) => {
      return moment(e.value).format("HH:mm DD/MM/YYYY");
    },
    resizable: true,
    sortable: true,
    minWidth: 200,
    type: "date",
  },
  {
    headerName: "Action",
    field: "action",
    suppressMenu: true,
    resizable: false,
    sortable: false,
    pinned: "right",
    cellRenderer: "BtnCellRenderer",
    maxWidth: 120,
  },
];

const Admin = () => {
  const [visible, setVisible] = useState(false);
  const [ud, setUd] = useState(false);

  if (!token) {
    console.log("Invalid token")
    return <Redirect to="/auth" />;
  }
  // className="main-bg"
  return (
    <>
      <div className="content">
        <div className="top-part">
          <i
            // style={{ marginRight: 20 }}
            className="fa fa-sign-out fa-2x"
            aria-hidden="true"
            onClick={() => {
              localStorage.clear();
              window.location.href = "/auth";
            }}
          ></i>
        </div>
        <div className="top-title-frame">
          <div>LIST OF USER TOKEN</div>
          <ButtonCustom
            onClick={() => {
              setVisible(true);
              console.log("hello");
            }}
            text={"Add"}
            loading={false}
            type={"add"}
          ></ButtonCustom>
        </div>
        <ServerSideTable
          ud={ud}
          setUd={setUd}
          columnDefs={columnDefs}
          LINK={URL.USERTOKEN}
        />
      </div>
      <CreatePop setUd={setUd} visible={visible} setVisible={setVisible} />
    </>
  );
};
export default Admin;

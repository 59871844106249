/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import * as URL from '../config/config';

let baseUrl = URL.BASE_URL;

const API = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

API.interceptors.request.use((request) => {
  let token = localStorage.getItem('token');
  console.log("token======>", token);

  if (token) {
    request.headers['Authorization'] = 'Bearer ' + token;
  }
  console.log("request=========>", request);
  return request;
});

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          return error.response;
        case 401:
          console.log('ERROR401========>', window.location);
          localStorage.clear();
          window.location.href = '/login';
          break;
        case 422:
          error.response.data.message = 'Xác thực không thành công!';
          // console.log('error=======>', error.response);
          return error.response;
        default:
          return Promise.reject({ response: error });
      }
      return Promise.reject({ response: error });
    }
  },
);

export default API;

export async function HandleMethodGet(url: any) {
  try {
    let { data: result } = await API.get(baseUrl + url);
    return result;
  } catch (error) {
    return error;
  }
}

export async function HandleMethodPost(url: any, data: any) {
  try {
    console.log("HandleMethodPost", {baseUrl, url, data})
    // let { data: result } = await API.post(baseUrl + url, data);
    const response = await API.post(baseUrl + url, data);
    // throw new Error("Loi 1")
    console.log(new Date(), "HandleMethodPost response", response);
    const { data: result } = response;
    return result;
  } catch (error) {
    return error;
  }
}

export async function HandleMethodPostCustom(url: any, data: any, config: any) {
  try {
    let { data: result } = await API.post(baseUrl + url, data, config);
    return result;
  } catch (error) {
    return error;
  }
}

export async function HandleMethodDelete(url: any, data?: any) {
  try {
    let { data: result } = await API.delete(baseUrl + url, { data: data });
    return result;
  } catch (error) {
    return error;
  }
}

export async function HandleMethodPut(url: any, data: any) {
  try {
    let { data: result } = await API.put(baseUrl + url, data);
    return result;
  } catch (error) {
    return error;
  }
}
export async function HandleMethodPATCH(url: any, data: any) {
  try {
    let { data: result } = await API.patch(baseUrl + url, data);
    return result;
  } catch (error) {
    return error;
  }
}

import React, { useState } from "react";
import InputField from "../InputFormCustom/InputField";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./auth.css";
import ButtonCustom from "../Buttons/ButtonCustom";
import * as URL from "./../../config/config";
import * as API from "../../Services/API";

const schema = yup.object().shape({
  UserName: yup.string().required("Required field."),
  PassWord: yup.string().required("Required field."),
  // note: yup.string().required(''),
});

const Authenticate = () => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (e: any) => {
    // e.preventDefault();

    setLoading(true);
    let resposnse = await API.HandleMethodPost(URL.LOG_IN, {
      username: e.UserName,
      password: e.PassWord,
    });
    console.log(
      "Success=======>",
      {
        username: e.UserName,
        password: e.PassWord,
      },
      resposnse
    );
    if (resposnse && resposnse.token) {
      localStorage.setItem("token", resposnse.token);
      setTimeout(() => {
        setLoading(false);
        window.location.href = "/";
      }, 300);
    } else {
      alert("Đăng nhập thất bại.")
    }
  };

  return (
    <div className="bg">
      <div className="frame">
        {/* <form onSubmit={onSubmit}> */}
          <div className="title" style={{ color: "blue", fontSize: 18 }}>
            LOGIN
          </div>

          <div style={{ marginTop: 20 }} className="frame-input">
            <div style={{ marginTop: 5 }}>
              <span style={{ color: "red" }}>* </span>Username:
            </div>
            <section className="mb-4" style={{ paddingLeft: "0px" }}>
              <InputField
                control={control}
                nameInput="UserName"
                placeholder="UserName"
                validateStatus={errors.UserName?.message}
                type="text"
              />
            </section>
          </div>

          <div
            style={{ marginTop: 30, marginBottom: 20 }}
            className="frame-input"
          >
            <div style={{ marginTop: 5 }}>
              <span style={{ color: "red" }}>* </span>Password:
            </div>
            <section className="mb-4">
              <InputField
                control={control}
                nameInput="PassWord"
                placeholder="PassWord"
                validateStatus={errors.PassWord?.message}
                type="password"
              />
            </section>
          </div>

          <div className="frame-button">
            <ButtonCustom
              onClick={handleSubmit(onSubmit)}
              type="submit"
              text={"Login"}
              loading={loading}
            ></ButtonCustom>
          </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default Authenticate;

import { FC } from "react";
import { Controller } from "react-hook-form";
import { Input, Form, InputNumber } from "antd";

interface IProps {
  control: any;
  nameInput: string;
  validateStatus?: string;
  type?: string;
  placeholder?: string;
  size?: any;
  disabled?: boolean;
  style?: any;
  styleInput?: any;
  min?: number;
  max?: number;
  value?: any;
  addonBefore?: any;
  prefix?: any;
  onChange?: (data?: any) => void;
}
const InputControllerField: FC<IProps> = (props) => {
  const {
    control,
    nameInput,
    validateStatus,
    type,
    placeholder,
    prefix,
    size,
    disabled,
    style,
    styleInput,
    onChange,
    min,
    max,
    value,
    addonBefore,
  } = props;

  return (
    <Controller
      {...props}
      control={control}
      name={nameInput}
      // name={nameInput}
      render={({ field }) => (
        <Form.Item
          style={style}
          validateStatus={validateStatus && "error"}
          help={validateStatus}
        >
          {type === "password" && (
            <Input.Password
              addonBefore={addonBefore}
              disabled={disabled}
              size={size}
              style={styleInput}
              placeholder={placeholder}
              type={type}
              height={100}
              prefix={prefix}
              {...field}
              onChange={onChange ? onChange : field.onChange}
            />
          )}

          {type === "number" && (
            <InputNumber
              // addonBefore={addonBefore}
              disabled={disabled}
              size={size}
              style={{ width: "100%", ...styleInput }}
              placeholder={placeholder}
              type={type}
              min={min}
              max={max}
              prefix={prefix}
              {...field}
              value={value !== undefined ? value : field.value}
            />
          )}
          {type === "text" && (
            <Input
              addonBefore={addonBefore}
              disabled={disabled}
              size={size}
              style={styleInput}
              placeholder={placeholder}
              type={type}
              height={100}
              prefix={prefix}
              {...field}
              onChange={onChange ? onChange : field.onChange}
            />
          )}
        </Form.Item>
      )}
    />
  );
};
export default InputControllerField;

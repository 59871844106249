import { message, Modal } from "antd";
import "antd/dist/antd.css";
import InputField from "../InputFormCustom/InputField";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as API from "../../Services/API";
import * as URL from "./../../config/config";
import { useEffect } from "react";

const CreatePop = ({ setUd, visible, setVisible }: any) => {
  const schema = yup.object().shape({
    UserName: yup.string().required("Required field."),
    UserToken: yup.string().required("Required field."),
    // note: yup.string().required(''),
  });

  const {
    handleSubmit,
    control,
    // setError,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("UserName", "");
    setValue("UserToken", "");
  });

  const onSubmit = async (data: any) => {
    console.log("data==========>", data);
    let res = await API.HandleMethodPost(URL.USERTOKEN, {
      userName: data.UserName,
      usertoken: data.UserToken,
    });
    console.log("res=======>", res);
    if (res && res.us && res.us.userName) {
      message.success("Add user token successful!");
      setVisible(false);
      setUd(true);
    } else {
      message.success("Add user token failed!");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "red",
      }}
    >
      <Modal
        title="Add user token popup"
        centered={true}
        visible={visible}
        onOk={handleSubmit(onSubmit)}
        onCancel={() => setVisible(false)}
        width={400}
      >
        <div className="frame-input">
          <div>
            <span style={{ color: "red" }}>* </span>Username:
          </div>
          <section className="mb-4" style={{ paddingLeft: "0px" }}>
            <InputField
              control={control}
              nameInput="UserName"
              placeholder="UserName"
              validateStatus={errors.UserName?.message}
              type="text"
            />
          </section>
        </div>

        <div className="frame-input">
          <div>
            <span style={{ color: "red" }}>* </span>Usertoken:
          </div>
          <section className="mb-4" style={{ paddingLeft: "0px" }}>
            <InputField
              control={control}
              nameInput="UserToken"
              placeholder="UserToken"
              validateStatus={errors.UserToken?.message}
              type="text"
            />
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default CreatePop;
